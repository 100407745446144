@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Montserrat", sans-serif;
}

/* Rich Text */

.rich-text > p {
  @apply mb-4 last:mb-0;
}

.rich-text > ul,
.rich-text > ol {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.rich-text > ul {
  list-style-type: disc;
}

.rich-text > ol {
  list-style-type: decimal;
}

.rich-text .list {
  @apply list-none p-0;
}

.rich-text a {
  word-break: break-all;
}

/* Section */

.section {
  @apply py-6;
}

.title {
  font-size: 84px;
  line-height: 1.31;
}

.registration-title {
  @apply mx-auto mb-[40px]  text-center text-[34px] font-bold leading-[1.31]  lg:text-[48px] xl:text-[68px];
}

.section-title {
  @apply mb-8 text-3xl  font-bold md:mb-12 md:text-4xl lg:mb-16;
}

.section-description {
  @apply mb-16 text-center text-4xl md:text-2xl;
}

.user_pic::before {
  display: block;
  content: url(/images/avatar.svg);
  margin-right: 10px;
}

.user_pic .borderOnExit {
  border-right: 2px solid rgb(255, 255, 255, 0.1);
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.hero {
  @media screen and (min-width: 768px) {
    background-position: right bottom -25px;
    background-image: url("/images/hero/laptop.png");
  }
  @media screen and (min-width: 976px) {
    background-position: right bottom -35px;
  }
  @media screen and (min-width: 1280px) {
    background-position: right bottom -45px;
    background-size: 1045px;
  }
}
.it {
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-image: url("/images/it/bgMobile.png");

  @media screen and (min-width: 1280px) {
    background-image: url("/images/it/bgDesktop.png");
  }
}

.link {
  @apply duration-300 ease-linear hover:text-accent focus:text-accent;
}

.markTextList {
  counter-reset: index;
}
.markTextList > li:before {
  @apply absolute text-9xl font-black;

  counter-increment: index;
  content: counter(index);
}

.ar-1 {
  aspect-ratio: 1;
}

@media screen and (min-width: 768px) {
  .ar-2 {
    aspect-ratio: 2;
  }
}

@media screen and (min-width: 768px) {
  .ar-3 {
    aspect-ratio: 3;
  }
}

.rocket {
  position: relative;
}
.rocket::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 12px;
  height: 11px;

  background-image: url(../public/images/next/rocket.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.calendar {
  position: relative;
}
.calendar::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 12px;
  height: 11px;

  background-image: url(../public/images/next/сalendar.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-list {
  @media screen and (min-width: 976px) {
    background-image: url(../public/images/next/bg-next.png);
    background-position: right top;

    background-repeat: no-repeat;
    background-size: 59%;
  }
}

/* Directions */
.directions-list > li:nth-child(1) {
  @apply bg-pink-400;
}

.directions-list > li:nth-child(2) {
  @apply bg-green-600;
}

.directions-list > li:nth-child(3) {
  background-color: rgb(14 116 144);
}

.directions-list > li:nth-child(4) {
  @apply bg-red-600;
}

/* Form */

.loginForm .form-input {
  @apply border-2 border-transparent;
}

.loginForm .form__input:focus ~ .form__label,
.loginForm .form__input:not(:placeholder-shown) ~ .form__label {
  @apply top-0 text-white;
  transform: translateY(calc(-100% - 6px));
}

.form {
  @apply flex flex-col gap-16;
}

.form__group {
  @apply relative;
}

.form__label {
  @apply absolute top-1/2 left-3 -translate-y-1/2 transform text-black duration-200 ease-linear;
}

.form__wrapper {
  @apply mx-auto w-full max-w-md rounded-xl;
}

.form__group:last-of-type + .form__btn {
  margin-top: 34px;
}
.form__message:not(:last-of-type) {
  margin-bottom: 42px;
}
.form__message:last-of-type {
  margin-bottom: 34px;
}

.form__input {
  @apply outline-none w-full rounded-md border border-black p-4 text-black;
}

.form__input:focus {
  @apply border-accent;
}

.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown) ~ .form__label {
  @apply top-0 left-0;
  transform: translateY(calc(-100% - 6px));
}

.form__leeloo {
  display: flex;
  justify-content: center;
}

.form__message {
  @apply absolute top-full left-0 mt-2 text-xs text-warning;
}

.loader__wrapper {
  @apply mx-auto max-w-[150px];
}

/* Modal */
.modal__overlay {
  background: rgba(0, 0, 0, 0.6);
}

.modal-progress {
  padding-top: 25px;
  pointer-events: none;
}

.modal-progress-bar {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 1rem 0;
}
.modal-progress-bar .is-active:before {
  background-color: white;
  border-color: #9c6fe2;
}
.modal-progress-bar > .item {
  flex: 2;
  position: relative;
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 1.5;
  color: #9c6fe2;
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  text-align: center;
  border-bottom: 3px solid #9c6fe2;
}
.modal-progress-bar > .item:first-child,
.modal-progress-bar > .item:last-child {
  flex: 1;
}
.modal-progress-bar > .item:first-child {
  text-align: left;
  padding-left: 12px;
}
.modal-progress-bar > .item:last-child {
  text-align: right;
}
.modal-progress-bar > .item:before {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  border: 4px solid #9c6fe2;
  position: absolute;
  left: calc(50% - 15px);
  bottom: -15px;
  transition: all var(--fast) linear;
}
.modal-progress-bar > .item:first-child:before {
  left: 0;
}
.modal-progress-bar > .item:last-child:before {
  right: 0;
  left: auto;
}
.modal-progress-bar > .item.is-active:before {
  transform: scale(1.33);
}
.modal-progress-bar > .item span {
  transition: opacity var(--fast) linear;
}
.modal-progress-bar > .item:not(.is-active) span {
  opacity: 0;
}

/* Quiz */

.answer__input:checked + .answer__checkbox {
  @apply bg-current;
}
